import React, { useState, useRef } from 'react';
import 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import TealToPinkAngleBackground from 'static/images/background_images/CreateMathPlaylist.png';
import PlaceholderImage from 'static/images/background_images/PlaceHolderFullMuzologyOnboard.png';
import { useForm } from 'react-hook-form';
import { useUserAction } from 'state/User/UserState';
import { useUser } from 'state/User/UserState';
import OnboardBoy from 'static/images/onboarding/OnboardBoy.png';
import BG from 'static/images/onboarding/OnboardBG.png';
import { useAnalytics } from '../../../../analytics/AnalyticsProvider';
import { MUZOLOGY_WEBSITE_URL } from '../../../../config';
import { useAuth } from '../../../../context/Auth/AuthProvider';
import { onSubmitDTCUser, BoxShadowCss } from '../../Helpers';
import { PrivacyTos, OnBoardingInput, Header, Button } from '../../Components';
import { LoadingOverlay } from '../../../../lib/components/loading';

function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
}


const CreateAccount = ({ setOnboardState, onboardFlag }) => {
    console.log('🚀 ~ CreateAccount ~ onboardFlag:', onboardFlag);
    // get the user
    const user = useUser();
    //  update user state
    const { updateUser } = useUserAction();
    //  default form values
    const defaultValues = {
        terms: false,
        user_name: user.username ?? user.email
    };
    // useForm setup for onboard form with react-hook-form
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    console.log('🚀 ~ CreateAccount ~ formErrors:', formErrors);
    // analytics to track the onboarding
    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    // loading state while saving the form
    const [saving, setSaving] = useState(false);

    //header string
    const header = 'Start Learning Now!';
    const timesTableProduct = false;
    // parent submits onboard form
    const onSubmit = (data) => {
        onSubmitDTCUser(data, {
            timesTableProduct,
            setSaving,
            setError,
            track,
            updateUser,
            setOnboardState,
            onboardFlag
        });
    };
    const { logout } = useAuth();
    return (
        <div tw='relative w-full mx-auto max-w-[370px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-[1160px] overflow-auto'>
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={BG} tw=' hidden sm:block absolute inset-0 rounded-lg' />
                <LoadingOverlay show={saving} fixed={false} background='#35107688' />
                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 py-0 lg:py-10'>
                    {/* Right Column - Contains Image Elements and Header Copy */}
                    <div tw='w-full lg:w-[44%] relative'>
                        {/* placeholder for now - gif or video will be added here later */}
                        <div tw=' hidden lg:block absolute w-full bottom-[-60px] right-[100px]'>
                            <div tw=' w-[790px]'>
                                <img src={OnboardBoy} tw='w-full h-auto' />
                            </div>
                        </div>
                    </div>

                    {/* Left Column - Contains Form Elements */}
                    <div tw='w-full lg:w-[56%] sm:relative select-none'>
                        <div
                            tw='sm:relative rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            {/* cancel button */}
                            <div
                                tw='text-[#999999] hover:text-[black] active:text-[#9C9C9C] absolute right-4 top-4 cursor-pointer'
                                onClick={() => {
                                    // const url = `${MUZOLOGY_WEBSITE_URL}onboarding?email=${user.email}`;
                                    // logout(MUZOLOGY_WEBSITE_URL);
                                    deleteCookie('sessionid');
                                    window.location.href = MUZOLOGY_WEBSITE_URL;
                                }}
                            >
                                <svg tw='h-4 sm:h-5 w-4 sm:w-5' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M1.105 0.00999838H3.905L10.975 10.475L18.29 0.00999838H20.95L12.41 12.19L21.44 25H18.57L10.975 13.975L3.275 25H0.615L9.575 12.12L1.105 0.00999838Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </div>
                            <div tw='text-[22px] sm:text-2xl text-center font-semibold'>Start your 7-day FREE TRIAL <br/> with Muzology now!</div>
                            <div tw='text-center mb-6 sm:mb-3 mt-1 sm:mt-0'>(No credit card required)</div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* Username Input */}
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label='Username' register={register} name='user_name' required={true}/>
                                    {formErrors.username?.message &&
                                        <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.username?.message}</div>}
                                    {formErrors.user_name &&
                                        <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>Please enter a username for your child.</div>}
                                </div>
                                {/* Password Input */}
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label='Password' register={register} name='password' required={true} password={true}/>
                                    {formErrors.password && <div
                                        tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.password.message?.length ? formErrors.password.message : 'Password must have at least 6 characters'}</div>}
                                </div>
                                {/* Privacy Policy and TOS */}
                                <div tw='pt-4'>
                                    <PrivacyTos register={register} formErrors={formErrors}/>
                                </div>
                                <div tw='w-full flex justify-center  pt-7 md:pt-[43px]'>
                                    <Button buttonCopy='Create Account' clearErrors={clearErrors} trigger={trigger}/>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default CreateAccount;