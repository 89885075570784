import React, { useState, useRef } from 'react';
import 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import BalanceText from 'react-balance-text';
import TealToPinkAngleBackground from 'static/images/background_images/CreateMathPlaylist.png';
import PlaceholderImage from 'static/images/background_images/PlaceHolderFullMuzologyOnboard.png';
import { Controller, useForm } from 'react-hook-form';
import { useUserAction } from 'state/User/UserState';
import { useUser } from 'state/User/UserState';
import OnboardBoy from 'static/images/onboarding/OnboardBoy.png';
import BG from 'static/images/onboarding/OnboardBG.png';
import tw from 'twin.macro';
import { useAnalytics } from '../../analytics/AnalyticsProvider';
import OnBoardingInput from '../../components/OnBoarding/Components/OnBoardingInput';
import PrivacyTos from '../../components/OnBoarding/Components/PrivacyTos';
import { MUZOLOGY_WEBSITE_URL } from '../../config';
import { onSubmitDTCUser, BoxShadowCss } from '../../components/OnBoarding/Helpers';
import { Button } from '../../components';
import { useQuery } from '../../hooks/useQuery';
import { LoadingOverlay } from '../../lib/components/loading';
import { apiClient } from '../../utils/api-client';
import StudentPage from './StudentPage';
import UserTypeButtons from './UserTypeButtons';

const ErrorMessage = tw.div`text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1`;

const CreateAccount = ({ onAccountCreated, loading, setLoading, onSelectStudent }) => {
    const { email } = useQuery();
    // console.log('🚀 ~ CreateAccount ~ onboardFlag:', onboardFlag);
    // get the user
    // const user = useUser();
    //  update user state
    // const { updateUser } = useUserAction();
    //  default form values
    const focusEmail = !email;
    const defaultValues = {
        email: email ?? null,
        username: email ?? null,
        password: null,
        userType: null,
        terms: false
    };

    // defaultValues.email = `test+${Math.floor(Math.random() * 100000)}@muzology.com`;
    // defaultValues.username = defaultValues.email;

    // if development, set default values
    // defaultValues.password = 'mathrocks';
    // defaultValues.userType = 'educator';
    // defaultValues.terms = true;

    // useForm setup for onboard form with react-hook-form
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    // console.log('🚀 ~ CreateAccount ~ formErrors:', formErrors);
    // analytics to track the onboarding
    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    // loading state while saving the form
    const [saving, setSaving] = useState(false);
    const updateUser = null;
    //header string
    const header = 'Start Learning Now!';
    const timesTableProduct = false;
    // parent submits onboard form
    // const [loading, setLoading] = useState(false);
    const [showStudentPage, setShowStudentPage] = useState(false);

    const handleCreateAccount = (data) => {
        const userType = 'parent';
        // if (data.userType === 'student') {
        //     // show the student onboarding
        //     setShowStudentPage(true);
        //     // onSelectStudent?.();
        //     return;
        // }
        setLoading(true);
        console.log('create account:', data);
        const { email, username, password } = data;
        // TODO: make the usertype a parameter and not part of the url
        let url = `/api/signup/${userType === 'educator' ? 'teacher-trial' : 'parent'}/`;
        apiClient.post(url, { email, username, password, userType })
            .then(response => {
                setLoading(false);
                const { data } = response;
                console.log('Parent account created', data);
                onAccountCreated?.(data);
                // setStep(1);
            }).catch(err => {
                console.error(err);
                const { response } = err;
                if (response?.data) {
                    const errorData = response.data;
                    console.log('error data:', errorData);
                    // Loop over API errors and set them to the form
                    Object.entries(errorData).forEach(([field, messages]) => {
                        console.log('field', field, 'messages', messages);
                        setError(field, {
                            type: 'server',
                            message: messages[0] // Assuming the first error message is relevant
                        });
                    });
                }
                setLoading(false);
            });
    };

    return (
        <div
            tw='relative w-full mx-auto overflow-auto'
            css={[
                tw`max-w-[400px]`
                // false && tw`max-w-[370px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-[1160px] `
            ]}
        >
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={BG} tw=' hidden sm:block absolute inset-0 rounded-lg'/>
                <LoadingOverlay show={saving} fixed={false} background='#35107688'/>

                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 py-0 lg:py-5 pb-0'>
                    {/* Left Column - Contains Image Elements and Header Copy */}
                    <div
                        tw='w-full lg:w-[44%] relative'
                        css={[showStudentPage && tw`hidden`]}
                    >
                        {/* placeholder for now - gif or video will be added here later */}
                        <div tw=' hidden lg:block absolute w-full bottom-[-60px] right-[200px]'>
                            <div
                                tw='w-[931px]'
                            >
                                <img src={OnboardBoy} tw='w-full h-auto' alt='Boy'/>
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Contains Form Elements */}
                    {showStudentPage ? (<StudentPage onCancel={() => setShowStudentPage(false)}/>) : (
                        <div tw='w-full lg:w-[56%] sm:relative select-none'>
                            <div
                                tw='sm:relative rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                                css={[BoxShadowCss]}
                            >
                                {/* cancel button */}
                                <button
                                    tw='text-[#999999] hover:text-[black] active:text-[#9C9C9C] absolute right-4 top-4 cursor-pointer'
                                    onClick={() => {
                                        // const url = `${MUZOLOGY_WEBSITE_URL}onboarding?email=${user.email}`;
                                        window.location.href = MUZOLOGY_WEBSITE_URL;
                                        // window.history.back();
                                    }}
                                >
                                    <svg tw='h-4 sm:h-5 w-4 sm:w-5' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z'
                                            fill='currentColor'
                                            // fill='#0F1729'
                                        />
                                    </svg>
                                </button>
                                <div tw='text-[21px] sm:text-2xl text-center font-semibold'>
                                    <BalanceText>
                                        Start your Muzology FREE trial now!
                                    </BalanceText>
                                </div>
                                <div tw='text-center mb-6 sm:mb-3 mt-1 sm:mt-0'>
                                    No credit card required
                                </div>

                                <div tw='text-center mb-6 sm:mb-3'>
                                    (<span tw='font-semibold'>Are you an Educator?</span> <a tw='underline' href={`${MUZOLOGY_WEBSITE_URL}signup`}>Click here</a>.)
                                </div>

                                <form id='signup-form' onSubmit={handleSubmit(handleCreateAccount)} tw='flex flex-col gap-0'>
                                    {/* Email Input */}
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label='Email' register={register} name='email' required={true} email={true} focus={focusEmail}/>
                                        {formErrors.email?.message?.length > 0 && <ErrorMessage>* {formErrors.email?.message}</ErrorMessage>}
                                        {formErrors.email?.type === 'required' && <ErrorMessage>* Please enter your email address</ErrorMessage>}
                                    </div>

                                    {/* Username Input */}
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label='Username' register={register} name='username' required={true} email={false}/>
                                        {formErrors.username?.message?.length > 0 && <ErrorMessage>* {formErrors.username?.message}</ErrorMessage>}
                                        {formErrors.username?.type === 'required' && <ErrorMessage>* Please enter a username</ErrorMessage>}
                                    </div>
                                    {/* Password Input */}
                                    <div tw='mb-4 md:mb-5'>
                                        <OnBoardingInput label='Password' register={register} name='password' required={true} password={true} focus={!focusEmail}/>
                                        {formErrors.password &&
                                            <ErrorMessage>* {formErrors.password.message?.length ? formErrors.password.message : 'Password must have at least 6 characters'}</ErrorMessage>}
                                    </div>

                                    {/*
                                    <div tw='mb-4 md:mb-4'>
                                        <label tw='block text-base font-normal text-[#303030] mb-1'>I Am A</label>
                                        <Controller
                                            name='userType'
                                            control={control}
                                            rules={{ required: 'Please select a user type' }}
                                            render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                                                <UserTypeButtons
                                                    selectedUserType={value}
                                                    selectUserType={(value) => onChange(value)}
                                                />
                                            )}
                                        />
                                        {formErrors.userType && <ErrorMessage>* {formErrors.userType.message}</ErrorMessage>}
                                    </div>
                                    */}

                                    {/* Privacy Policy and TOS */}
                                    <div tw='pt-2'>
                                        <PrivacyTos register={register} formErrors={formErrors}/>
                                    </div>

                                    <div tw='w-full flex justify-center mt-3 mb-6 md:mb-0'>
                                        <Button variant='pink' tw='py-4 text-base' text='Create Account' clearErrors={clearErrors} trigger={trigger}/>
                                    </div>

                                </form>

                            </div>

                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default CreateAccount;