import React, { useMemo } from 'react';
import VideoSlider from 'components/VideoSlider';
import RenderOnViewPortEntry from 'components/RenderOnViewPortEntry/RenderOnViewPortEntry';

import 'twin.macro';
import HomePageVideoRows from 'components/HomePageVideoRows/HomePageVideoRows';
import { catalog_math_v1 } from '../config';
import { useVideosWithStatsMap } from '../state/Student/VideoStats';
import { useCatalogPlaylists, useCourseAndVideos } from '../state/CatalogState';
import { useAccount } from '../state/User/UserState';

// recommend a playlist
const playlist_1 = '0f0315f6-5678-46d8-bbcb-8d2b7eb90162'; // K- 4th grade: Math Vocabulary
const playlist_2 = '42515555-2cf3-4f63-998f-2206ed62ba22'; // 5th grade: The Number System - Part 1
const playlist_3 = '9326854e-7b2a-446f-ad82-6f8507b51915'; // 6th grade: Expressions & Equations - Part 2
const playlist_4 = '10763313-3ab3-4192-aa14-6d6075de2728'; // 7th grade: The Number System - Part 4
const playlist_5 = 'c3c3cc75-ca92-49f5-92b2-7b526a1bc47f'; // 8th grade: The Number System - Part 5
const playlist_6 = '91a72226-bdf0-44bc-8e03-b73954c783c7'; // 9-12th grade: Functions

// recommended group
const group_1 = '0f0315f6-5678-46d8-bbcb-8d2b7eb90162'; // K- 4th grade: Math Vocabulary
const group_2 = '42515555-2cf3-4f63-998f-2206ed62ba22'; // 5th grade: The Number System - Part 1
const group_3 = '9326854e-7b2a-446f-ad82-6f8507b51915'; // 6th grade: Expressions & Equations - Part 2
const group_4 = '10763313-3ab3-4192-aa14-6d6075de2728'; // 7th grade: The Number System - Part 4
const group_5 = 'c3c3cc75-ca92-49f5-92b2-7b526a1bc47f'; // 8th grade: The Number System - Part 5
const group_6 = '91a72226-bdf0-44bc-8e03-b73954c783c7'; // 9-12th grade: Functions

const recommended_map = {
    // K through 4th grade
    0: playlist_1,
    1: playlist_1,
    2: playlist_1,
    3: playlist_1,
    4: playlist_1,

    5: playlist_2, // 5th Grade
    6: playlist_3, // 6th Grade
    7: playlist_4, // etc
    8: playlist_5,

    // 9-12th Grades
    9: playlist_6,
    10: playlist_6,
    11: playlist_6,
    12: playlist_6,
    13: playlist_6
};

const getCourseParentGroups = (course) => course?.groups?.filter(group => !group.parent) ?? [];
const getCourseParentGroup = (course, groupId) => course?.groups?.find(group => group.id === groupId) ?? null;
const getCourseGroupNamed = (course, groupName) => course?.groups?.find(group => group.title === groupName) ?? null;

const RecommendedPlaylist = ({ sliderResponsive, grade, teacher }) => {
    const videos = useVideosWithStatsMap();
    const playlists = useCatalogPlaylists();
    console.log('all playlists', playlists);
    const recommendedCourse = useCourseAndVideos('b2b-recommended-videos');

    // let grade_level = student.grade_level === 0 ? 6 : student.grade_level;
    // let grade_level = grade > 0 ? grade : 6; // default to 6
    // clip grade_level between 0 and 13
    let grade_level = Math.max(0, Math.min(13, grade));
    // console.log('<RecommendedPlaylist> grade level:', grade_level);
    // console.log('<RecommendedPlaylist> recommendedCourse:', recommendedCourse );

    let selectedVideosV2 = useMemo(() => {
        // console.log('<RecommendedPlaylist> recommendedCourse:', recommendedCourse);
        if (recommendedCourse) {
            // console.log('<RecommendedPlaylist> grade level', grade_level);
            // use the null course to get the recommended group
            const parentGroups = recommendedCourse?.groups?.filter(item => item.parent === null) ?? [];
            // console.log('<RecommendedPlaylist> parentGroups:', parentGroups);

            // get the parent groups
            if (grade_level >= 0) {
                const gradeKey = {
                    0: 0,
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 1,
                    5: 2,
                    6: 3,
                    7: 4,
                    8: 5,
                    9: 6,
                    10: 7,
                    11: 7,
                    12: 7,
                    13: 7
                };
                // console.log('<RecommendedPlaylist> grade key', gradeKey[grade_level]);
                const parentGroup = parentGroups[gradeKey[grade_level]];
                // console.log('parentGroups', parentGroups);
                // console.log('recommended grade', grade_level);
                // console.log('recommended group', parentGroup?.title);
                // console.log('<RecommendedPlaylist> parentGroup:', parentGroup?.title);
                // return the videos in the group
                // console.log('<RecommendedPlaylist> parentGroup:', parentGroup, videos);
                const childGroup = recommendedCourse?.groups?.find(group => group?.parent === parentGroup?.id);
                // console.log('<RecommendedPlaylist> childGroup:', childGroup);
                // grab the video for each video in the group
                const vids = childGroup?.lessons?.map(lessonId => {
                    return Object.values(videos).find(lesson => lesson.lesson_id === lessonId);
                    // recommendedCourse.lessons?.find(lesson => lesson.id === lessonId);
                    // return videos[lessonId];
                }) ?? [];
                // console.log('<RecommendedPlaylist> vids:', vids);
                return vids.filter(vid => vid);
                // return (parentGroup?.videos?.map(videoId => videos[videoId]) ?? []).filter(video => video);
                // const video = videos[videoId];
                // if (video) {
                //     selectedVideos.push(video);
                // }
                // selectedVideos.push(video);
            }
        }
        return null;
    }, [grade_level, recommendedCourse, videos]);
    // console.log('selectedVideosV2:', selectedVideosV2);

    // console.log('Recommended grade level:', grade_level);
    // find users recommended playlist
    let selectedVideosV1 = useMemo(() => {
        let playlists_list = Object.values(playlists);
        console.log('selectedVideosV1: playlists:', playlists_list, playlists_list?.length);
        if (playlists_list?.length > 0) {
            console.log('selectedVideosV1: collect videos');
            const selectedVideos = [];
            let map_match = recommended_map[grade_level];
            if (!map_match)
                map_match = playlist_6;
            console.log('selectedVideosV1: map_match:', map_match, grade_level, recommended_map[grade_level]);
            const playlist = playlists[map_match];
            console.log('selectedVideosV1: playlist:', playlist);
            if (playlist) {
                let playlistVideos = playlist.videos;
                playlistVideos.forEach((videoId) => {
                    const video = videos[videoId];
                    if (video) {
                        selectedVideos.push(video);
                    }
                });
                // add 'Fractions Made Simple' to the 1st grade playlist
                if (map_match === playlist_1) {
                    const video = videos['b0952001-4bf2-42e0-a294-66bd39047417'];
                    if (video)
                        selectedVideos.push(video);
                }
            }
            return selectedVideos;
        }
        return null;
    }, [playlists, videos, grade_level]);
    console.log('selectedVideosV1:', selectedVideosV1);
    console.log('selectedVideosV2:', selectedVideosV2);
    const account = useAccount();
    let selectedVideos = selectedVideosV2;
    if (account?.course_library_id === catalog_math_v1) {
        selectedVideos = selectedVideosV1;
    }

    console.log('selectedVideos:', selectedVideos);
    if (!selectedVideos || selectedVideos?.length === 0) {
        console.log('No recommended videos');
        return null;
    }
    // if (!videos || !playlists)
    //     return null;
    // if( !recommendedCourse ) {
    //     console.log('No recommended course');
    //     return null;
    // }

    // console.log('TCL: RecommendedPlaylist -> render -> videos', selectedVideos);
    // console.log('grade recommend: ', selectedVideos);
    return (
        <>
            {
                teacher ?
                    <>
                        <RenderOnViewPortEntry threshold={0.25}>
                            <div tw='mt-14 hidden md:block'>
                                <HomePageVideoRows title='Recommended Videos' videos={selectedVideos} teacher={teacher} />
                            </div>
                        </RenderOnViewPortEntry>
                        <div tw='mt-14 ml-[-22vw] mr-[-7vw] md:hidden'>
                            <div tw='text-white text-base tiny:text-xl font-semibold ml-[34vw] max-w-[320px] xsm:max-w-[360px]'>Recommended Videos</div>
                            <VideoSlider videos={selectedVideos} noSideArrow={true} teacher={teacher} />
                        </div>
                    </>

                    : <VideoSlider videos={selectedVideos} cols={3} teacher={teacher} sliderResponsive={sliderResponsive} />
            }
        </>

    );
};

export default RecommendedPlaylist;
