import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useLockedVideoQuiz } from 'app/containers/shared/QuizSessionContainer/QuizModal';
import { useUser } from 'state/User/UserState.js';
import { useAssignmentsForVideo } from 'state/Student/AssignmentsState';
import { useMainCourseAndVideos } from 'state/CatalogState';
import { useVideoStats, useVideoWithStats } from 'state/Student/VideoStats';
import VideoSession from './VideoSession';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import './Session.css';

export const VideoLoading = () => <div className=''>
    <LoadingScreen />
</div>;

export function usePlaylist(course, video_id) {
    return course?.playlists?.find((playlist) =>
        playlist.videos?.find((video) => video?.id === video_id)
    ) ?? null;
}
const VideoSessionContainer = (props) => {
    const navigate = useNavigate();

    const { video_id } = useParams();

    const user = useUser();
    const course = useMainCourseAndVideos();
    const stats = useVideoStats();
    const video = useVideoWithStats(video_id);
    const playlist = usePlaylist(course, video_id);

    const assignments = useAssignmentsForVideo(video_id);
    const videoAssignmentByDate = assignments.sort((a, b) => new Date(b.created) - new Date(a.created));

    const [WarmupQuizModal, closeQuiz, isWarmupOpen] = useLockedVideoQuiz({
        videoId: video_id,
        onCancel: () => navigate(-1)
    });

    if (!video) {
        return <VideoLoading />;
    }

    return (
        <React.Fragment key={video.id}>
            <Helmet>
                <title>{video.title ?? 'Video'}</title>
            </Helmet>

            {!user?.instructor && <WarmupQuizModal />}

            <VideoSession
                css={isWarmupOpen && css`visibility: hidden; display:none;`}
                aria-hidden={isWarmupOpen ? 'true' : 'false'}
                user={user}
                video={video}
                stats={stats}
                playlist={playlist}
                assignment={videoAssignmentByDate[0] ?? null}
            />
        </React.Fragment>
    );
};

export default VideoSessionContainer;